import { PagefindUI } from '@pagefind/default-ui'

const dialog = document.querySelector("dialog");
const showSearch = document.querySelector("#search_link");
const search = document.getElementById('search_container');
search.addEventListener('click', (event) => event.stopPropagation());
dialog.addEventListener('click', () => {
    dialog.close()
});
showSearch.addEventListener("click", (e) => {
    e.preventDefault();
    dialog.showModal();
});

new PagefindUI({element: "#search", showSubResults: true, autofocus: true});

